import React from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ContactForm from '../../../ContactForm';

const AddContactModal = ({ showModal, setShowModal, contact, onActionComplete }) => (
    <Modal
        title={contact && contact.id ? 'Edit contact' : 'Add new contact'}
        footer={null}
        onCancel={() => setShowModal(false)}
        open={showModal}>
        <ContactForm
            // 250331 YUANKUI: THX-157: Flag at checkout if site contact missing mobile number
            //        Pass selected contact (mobile number missing) from the parent component
            record={contact}
            onSubmit={() => setShowModal(false)}
            goBack={() => setShowModal(false)}
            // 20241015 YUANKUI TRY TO FIX TH-37
            //          Expose onActionComplete to select the latest created contact
            onActionComplete={val => onActionComplete(val)} />
    </Modal>
);

AddContactModal.propTypes = {
    // 250331 YUANKUI: THX-157: Flag at checkout if site contact missing mobile number
    //        Pass selected contact (mobile number missing) from the parent component
    contact: PropTypes.object,
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    // 20241015 YUANKUI TRY TO FIX TH-37
    //          Expose onActionComplete to select the latest created contact
    onActionComplete: PropTypes.func.isRequired,
};
AddContactModal.defaultProps = {
    // 250331 YUANKUI: THX-157: Flag at checkout if site contact missing mobile number
    //        Pass selected contact (mobile number missing) from the parent component
    contact: undefined,
};

export default AddContactModal;
