import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Form, Input, Select, Button } from 'antd';

import { isEmpty } from 'underscore';
import { createCustomerContactRequest, updateCustomerContactRequest, getContactJobTitlesRequest } from '../../core/account/accountActions';

const ContactForm = forwardRef(({ record, onSubmit, hideButtons, goBack, onActionComplete }, ref) => {
    const [contactForm] = Form.useForm();
    const { contactId } = useParams();
    const { customerContactJobTitles } = useSelector(state => state.account);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContactJobTitlesRequest());
    }, []);

    // 250331 YUANKUI: THX-157: Flag at checkout if site contact missing mobile number
    //        WHEN record CHANGED, UPDATE THE FORM AND SHOW THE VALIDATION NOTICE.
    useEffect(() => {
        if (record) {
            const jobTitleId = customerContactJobTitles.find(job => job.description === record.jobTitle)?.id || record.jobTitle;
            contactForm.setFieldsValue({
                firstName: record.firstName,
                lastName: record.lastName,
                email: record.email,
                phoneNumber: record.phoneNumber,
                mobileNumber: record.mobileNumber,
                jobTitle: jobTitleId,
                optIn: record.optIn === '1',
            });
            contactForm.validateFields(); // Trigger validation to show the notice
        } else {
            contactForm.resetFields();
        }
    }, [record]);

    useImperativeHandle(ref, () => ({
        clearForm() {
            contactForm.resetFields();
        },
        validateForm() {
            return contactForm.validateFields();
        },
        submitForm() {
            contactForm.submit();
        },
    }));

    // if contact id is present, then we are editing an existing contact
    const { id, firstName, lastName, email, phoneNumber, mobileNumber, jobTitle, optIn } = record ?? {};

    const jobTitleId = customerContactJobTitles.find(job => job.description === jobTitle)?.id || jobTitle;

    const handleOnFinish = (values) => {
        if (isEmpty(record)) {
            // 20241015 YUANKUI TRY TO FIX TH-37
            //          Expose onActionComplete to select the latest created contact
            dispatch(createCustomerContactRequest({ data: values, onActionComplete }));
        } else {
            dispatch(updateCustomerContactRequest({ ...values, id }));
        }
        onSubmit();
    };

    return (
        <Form
            form={contactForm}
            onFinish={handleOnFinish}
            name="contact-form"
            layout="vertical">
            <Form.Item
                label="First Name"
                name="firstName"
                initialValue={firstName}
                rules={[{ required: true, message: 'Please input first name' }]}>
                <Input disabled={!!firstName} />
            </Form.Item>
            <Form.Item
                label="Last Name"
                name="lastName"
                initialValue={lastName}
                rules={[{ required: true, message: 'Please input last name' }]}>
                <Input disabled={!!lastName} />
            </Form.Item>
            <Form.Item
                label="Email Address"
                name="email"
                initialValue={email}
                rules={[
                    { type: 'email', message: 'Please input a valid email address' },
                ]}>
                <Input disabled={!!email} />
            </Form.Item>
            <Form.Item
                label="Phone Number"
                initialValue={phoneNumber}
                name="phoneNumber">
                <Input disabled={!!phoneNumber} />
            </Form.Item>
            <Form.Item
                label="Mobile Number"
                name="mobileNumber"
                initialValue={mobileNumber}
                rules={[{ required: true, message: 'Please input mobile number' }]}>
                <Input disabled={!!mobileNumber} />
            </Form.Item>
            <Form.Item
                label="Job Title"
                name="jobTitle"
                initialValue={jobTitleId}
                rules={[{ required: true, message: 'Please select job title' }]}>
                <Select defaultValue="Select a job title">
                    {customerContactJobTitles.map((job) => (
                        <Select.Option key={job.id} value={job.id}>{job.description}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Opt-in to marketing emails and updates from THX?"
                name="optIn"
                valuePropName="checked"
                initialValue={(optIn === undefined) || (optIn === '1')}>
                <Checkbox disabled={!!optIn} className="contact-form-checkbox" />
            </Form.Item>

            <Button hidden={hideButtons} type="primary" htmlType="submit">{(contactId) ? 'Update' : 'Submit'}</Button>
            <Button hidden={hideButtons} onClick={goBack} style={{ marginLeft: '10px' }} type="secondary">Back</Button>
        </Form>
    );
});

ContactForm.propTypes = {
    record: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        mobileNumber: PropTypes.string,
        jobTitle: PropTypes.string,
        optIn: PropTypes.string,
    }),
    onSubmit: PropTypes.func,
    hideButtons: PropTypes.bool,
    goBack: PropTypes.func,
    // 20241015 YUANKUI TRY TO FIX TH-37
    //          Expose onActionComplete to select the latest created contact
    onActionComplete: PropTypes.func,
};

ContactForm.defaultProps = {
    record: null,
    onSubmit: () => {},
    hideButtons: false,
    goBack: () => {},
    // 20241015 YUANKUI TRY TO FIX TH-37
    //          Expose onActionComplete to select the latest created contact
    onActionComplete: () => {},
};

export default ContactForm;
